.register_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 10px;
}
.form {
  padding: 20px 15px;
  border-radius: 10px;
  width: 100vw;
  max-width: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @apply bg-base-200
}
.form input{
  @apply my-2
}
.logo_container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.error_container {
  @apply flex justify-center items-center p-2 w-full h-full rounded-md border text-error border-error bg-error/10;
}

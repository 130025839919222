@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideIn {
  from {
    transform: translateX(150%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(150%);
  }
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}
.tabShape {
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
}
.popup-content {
  margin: auto;
  width: 95%;
  max-width: 700px;
  border-radius: 10px;
  padding: 10px;
  @apply bg-base-100;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}





/* Remove autofill background color on inputs */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
} */

/* For non-webkit browsers */
/* input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
  color: black !important;
} */

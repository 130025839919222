.pageContainer {
  @apply h-screen w-[100vw] flex justify-center items-center px-2 bg-base-100;
}

.form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
  border-radius: 1rem;
  width: 100vw;
  max-width: 300px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @apply bg-base-200;
}
.signedIn {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100vw;
  max-width: 300px;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  gap: 1rem; /* Added gap for spacing between items */
  @apply bg-base-200;
}

.form input{
  @apply my-2
}
.section{
  @apply max-w-[700px] mt-4 w-full break-words
}
.section h3 {
  @apply text-xl text-primary;
}
.section h2 {
  @apply mt-2 text-lg font-semibold;
}
.section ul {
  @apply mt-2 ml-10 list-disc;
}
